import { installMembersArea } from '@wix/members-area-integration-kit';
import EditorWrapper from './EditorWrapper';
import {
  CHALLENGES_PAGES,
  CHALLENGE_PAGE_ID,
  MEMBERSHIP_APP_ID,
  PAYMENT_PAGE_MANIFEST_KEY,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_ID,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_ID,
  CHALLENGES_LIST_PAGE_TYPE,
  GROUPS_APP_ID,
} from './app-config';
import { getChallengesManifest } from './manifest';
import AppManifest, { AppManagerEvent } from './types/manifest';
import { ISetupPagesOptions, PageData, TPARef } from './types/common';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export class ChallengesPlatform {
  private readonly editor: EditorWrapper;
  private readonly translations;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    flowAPI: EditorScriptFlowAPI,
    public readonly isADI?: boolean,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
    this.translations = flowAPI.translations;
  }

  async isChallengesInstalled() {
    return this.editor.isAppInstalled(this.appDefId);
  }

  async installDependencies() {
    const isMembershipInstalled = await this.editor.isAppInstalled(
      MEMBERSHIP_APP_ID,
    );

    const isGroupsInstalled = await this.editor.isAppInstalled(GROUPS_APP_ID);
    if (!isGroupsInstalled) {
      await this.editor.installTPA(GROUPS_APP_ID);
    }

    console.groupCollapsed('Members install');
    await installMembersArea();
    !isMembershipInstalled && (await this.editor.installTPA(MEMBERSHIP_APP_ID));
    console.groupEnd();

    await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);
  }

  async setupPages(options: ISetupPagesOptions) {
    const { showInEditorPageMenu = [], isFirstInstall } = options;
    const addedPages = await this.installPages(options.isFirstInstall);

    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    const { applicationId } = tpaData;
    const challengesPages = this.getChallengesTPARefs(
      allSitePages,
      applicationId,
    );

    // magic begins. We need to use new layout (Sidebar) for new users by default
    // this code setting value to public data that we cen use later in SP/Controller/Widget as regular setting key.
    if (isFirstInstall && !this.isADI) {
      const challengePage = challengesPages.find(
        (page) => page.tpaPageId === CHALLENGE_PAGE_ID,
      );

      const appData = await this.editor.getDataByAppDefId(this.appDefId);

      const allComps = await this.editor.getAllComponentsById(
        appData.applicationId,
      );

      const challengePageTpaSection = allComps.find(
        (comp) => comp.pageId === challengePage.pageRef.id,
      );

      if (!challengePageTpaSection) {
        return;
      }

      await this.editor.setPublicData(
        {
          id: challengePageTpaSection.id,
          type: 'DESKTOP',
        },
        {
          key: 'participantLayout',
          value: 'SIDEBAR',
        },
      );
    }
    // end of maaaaagic

    const thankYouPage = challengesPages.find(
      (page) => page.tpaPageId === THANK_YOU_PAGE_ID,
    );

    try {
      await this.editor.updatePage(thankYouPage.pageRef, {
        pageUriSEO: 'challenge-thanks',
      });

      // await this.editor.save();
      console.log('[challenges]: Thank you page migrated');
    } catch (e) {
      console.error('[challenges]:error in migrating thank you page');
    }
    if (isFirstInstall) {
      await this.editor.setPageInitialTitles(
        challengesPages,
        this.translations,
      );
    } else if (addedPages.length) {
      await this.editor.setPageInitialTitles(
        challengesPages.filter((page) => addedPages.includes(page.tpaPageId)),
        this.translations,
      );
    }

    const isPageUpdated = await this.editor.addPagesToEditorPageMenu(
      challengesPages,
      showInEditorPageMenu,
    );

    if (isPageUpdated || addedPages.length || isFirstInstall) {
      // await this.editor.save();
    }

    await this.linkPageWithManifest({
      manifestKey: PAYMENT_PAGE_MANIFEST_KEY,
      tpaPageId: PAYMENT_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: THANK_YOU_PAGE_MANIFEST_KEY,
      tpaPageId: THANK_YOU_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: CHALLENGES_LIST_PAGE_MANIFEST_KEY,
      tpaPageId: CHALLENGES_LIST_PAGE_ID,
      applicationId: this.appDefId,
    });
  }

  private async installPages(
    isFirstInstall: boolean = false,
  ): Promise<string[]> {
    const allSitePages = await this.editor.getAllPages();
    const addedPages = [];

    await Promise.all(
      CHALLENGES_PAGES.map(async (tpaPageId) => {
        const isPageInstalled = allSitePages.some(
          (page) => page.tpaPageId === tpaPageId,
        );

        if (
          !isPageInstalled &&
          (tpaPageId !== CHALLENGES_LIST_PAGE_ID ||
            (tpaPageId === CHALLENGES_LIST_PAGE_ID && isFirstInstall))
        ) {
          await this.editor.addPage(tpaPageId);
          addedPages.push(tpaPageId);
        }
      }),
    );

    return addedPages;
  }

  private getChallengesTPARefs(allSitePages, applicationId): TPARef[] {
    return allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          CHALLENGES_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId, title, managingAppDefId }) => {
        return {
          title,
          tpaPageId,
          managingAppDefId,
          pageRef: { id },
        };
      });
  }

  async getManifest(): Promise<AppManifest> {
    return getChallengesManifest(this.translations);
  }

  async linkPageWithManifest(options: {
    applicationId: string;
    manifestKey: string;
    tpaPageId: string;
  }) {
    const { applicationId, manifestKey, tpaPageId } = options;
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData = allSitePages.find((page) => {
        return (
          page.managingAppDefId === applicationId &&
          page.tpaPageId === tpaPageId
        );
      });
      const { id } = groupPage;
      await this.editor.setPageState({ [manifestKey]: [{ id }] });
    } catch (e) {
      console.error('Set group page state: FAIL');
    }
  }

  async deleteApp(eventPayload: {
    origin: string;
    publicUrl: string;
    pageRef: { id: string; type: string };
  }) {
    try {
      await this.editor.deletePage({
        pageRef: { id: eventPayload.pageRef.id },
      });
      await this.editor.deleteApp();
    } catch (e) {
      console.warn('[challenges]', e);
    }
  }

  async getTPASectionId(pageId: string): Promise<string> {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    const { applicationId } = tpaData;
    const challengesPages = this.getChallengesTPARefs(
      allSitePages,
      applicationId,
    );
    const challengePage = challengesPages.find(
      (page) => page.tpaPageId === pageId,
    );

    const appData = await this.editor.getDataByAppDefId(this.appDefId);

    const allComps = await this.editor.getAllComponentsById(
      appData.applicationId,
    );

    const challengePageTpaSection = allComps.find(
      (comp) => comp.pageId === challengePage.pageRef.id,
    );

    return challengePageTpaSection?.id || '';
  }

  async openDashboard(actionId: AppManagerEvent) {
    if (actionId === AppManagerEvent.OPEN_DASHBOARD) {
      return this.editor.openDashboard('/challenges');
    }
    if (actionId === AppManagerEvent.OPEN_CREATE_CHALLENGE) {
      return this.editor.openDashboard('/challenges/create');
    }

    if (actionId === AppManagerEvent.OPEN_SP) {
      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);

      const challengeListPageId = await this.getTPASectionId(
        CHALLENGES_LIST_PAGE_ID,
      );

      try {
        await this.editor.openSP(
          {
            id: challengeListPageId,
            type: 'DESKTOP',
          },
          this.appDefId,
          CHALLENGES_LIST_PAGE_TYPE,
        );
      } catch (e) {
        console.error('Error on open SP from App Manager:', e);
      }
    }

    return;
  }

  handleInstallError() {}
}
