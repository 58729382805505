import {
  ComponentDefinition,
  ComponentRef,
  PageData,
  PageRef,
  PageRouter,
  TPAAppRef,
  TPAComp,
  TPAComponentType,
  TPACompRef,
  TPARef,
} from './types/common';
import { PAGE_TITLES } from './app-config';

class EditorWrapper {
  constructor(private readonly editorSDK, private readonly token) {}

  async installApplication(appDefinitionId: string) {
    console.log(`[CHALLENGES] installing ${appDefinitionId}`);
    if (!(await this.isAppInstalled(appDefinitionId))) {
      return this.editorSDK.application.install(this.token, {
        appDefinitionId,
        initiatorAppDefinitionId: this.token,
      });
    }
    console.log(`[CHALLENGES] app ${appDefinitionId} is already installed`);
  }

  // Only wix apps are allowed installed in this way
  async installTPA(appDefinitionId: string): Promise<TPAAppRef> {
    if (!(await this.isAppInstalled(appDefinitionId))) {
      return this.editorSDK.document.tpa.add.application(this.token, {
        appDefinitionId,
      });
    }
    // TODO: get pageRef by appId
    return Promise.resolve({ instanceId: appDefinitionId });
  }

  private installTPAComponent(compDescr: TPAComp): Promise<TPACompRef> {
    return this.editorSDK.document.tpa.add.component(this.token, compDescr);
  }

  installTPAPage(appDefinitionId: string, pageId: string): Promise<TPACompRef> {
    const compDescr = {
      componentType: TPAComponentType.Page,
      appDefinitionId,
      page: {
        pageId,
      },
    };
    return this.installTPAComponent(compDescr);
  }

  async isAppInstalled(appDefinitionId: string) {
    return this.editorSDK.document.tpa.isApplicationInstalled(this.token, {
      appDefinitionId,
    });
  }

  async getAPI(appDefinitionId: string) {
    return this.editorSDK.application.getPublicAPI(this.token, {
      appDefinitionId,
    });
  }

  async setPublicData(
    compRef: ComponentRef,
    data: { key: string; value: any },
  ) {
    return this.editorSDK.document.tpa.data.set(this.token, {
      compRef,
      key: data.key,
      value: data.value,
      scope: 'COMPONENT',
    });
  }

  async isControllerAlreadyExist(controllerType: string) {
    const controllers = await this.editorSDK.controllers.listAllControllers(
      this.token,
    );
    for (const controller of controllers) {
      const data = await this.editorSDK.controllers.getData(
        this.token,
        controller,
      );
      if (data.type === controllerType) {
        return true;
      }
    }
    return false;
  }

  getMasterRef(): Promise<PageRef> {
    return this.editorSDK.siteSegments.getSiteStructure(this.token);
  }

  // addRouter(descr: RouterDescr) {
  //   return this.editorSDK.document.routers.add(this.token, descr);
  // }

  connectPage(pageRouter: PageRouter): Promise<void> {
    return this.editorSDK.document.routers.pages.connect(
      this.token,
      pageRouter,
    );
  }

  private addComponent(descr: {
    componentDefinition: ComponentDefinition;
    pageRef: PageRef;
  }) {
    return this.editorSDK.components.add(this.token, descr);
  }

  installController(pageRef: PageRef, controllerType: string) {
    const descr = {
      componentDefinition: {
        componentType: 'platform.components.AppController',
        data: {
          type: 'AppController',
          controllerType,
          applicationId: this.token,
          settings: JSON.stringify({}),
        },
      },
      pageRef,
    };
    return this.addComponent(descr);
  }

  async setPageInitialTitles(pages: TPARef[], i18n) {
    return Promise.all(
      pages.map(async (page) => {
        const title = i18n.t(PAGE_TITLES[page.tpaPageId]);

        if (title) {
          await this.updatePage(page.pageRef, {
            title,
          });
        } else {
          console.warn(page.tpaPageId, 'Page title is not provided');
        }
      }),
    );
  }

  async addPagesToEditorPageMenu(
    pages: TPARef[],
    showInPageEditorMenu: string[],
  ): Promise<Boolean> {
    const isPageUpdated: boolean[] = await Promise.all(
      pages.map(async (page) => {
        const { pageRef, tpaPageId } = page;
        const managingAppDefId = showInPageEditorMenu.includes(tpaPageId)
          ? this.token
          : this.token;

        if (managingAppDefId !== page.managingAppDefId) {
          await this.updatePage(pageRef, {
            managingAppDefId,
          });
          return true;
        }
        return false;
      }),
    );

    return isPageUpdated.some(Boolean);
  }

  async updatePage(pageRef, data: Partial<PageData>) {
    return this.editorSDK.document.pages.data.update(this.token, {
      pageRef,
      data,
    });
  }

  async openAppPage(pageId, appDefId) {
    // code is borrowed from Wix Forums, see
    // https://github.com/wix-private/app-market/blob/80fa930daf5076db9e41b6a49b4346da39a4652f/communities/communities-forum-app/src/assets/editor-script.js#L311
    const [{ applicationId }, allPages] = await Promise.all([
      this.getDataByAppDefId(appDefId),
      this.getAllPages(),
    ]);

    const page = allPages.find(
      ({ tpaPageId, tpaApplicationId }) =>
        tpaPageId === pageId && tpaApplicationId === applicationId,
    );

    if (page) {
      this.editorSDK.document.pages.navigateTo(this.token, {
        pageLink: { type: 'PageLink', pageId: page.id },
      });
    }
  }

  async openSP(compRef: ComponentRef, appDefId: string, widgetId: string) {
    const appData = await this.getDataByAppDefId(appDefId);
    const instance = await this.editorSDK.document.info.getAppInstance();
    const locale = await this.editorSDK.environment.getLocale();
    const settingsUrl =
      appData.widgets[widgetId]?.settings?.url ||
      appData.widgets[widgetId]?.settings?.urlV2;

    if (!settingsUrl) {
      return;
    }

    return this.editorSDK.editor.openComponentPanel(this.token, {
      url: `${settingsUrl}?instance=${instance}&origCompId=${compRef.id}&viewMode=editor&compId=tpaSettings&locale=${locale}`,
      type: this.editorSDK.editor.PanelType.Settings,
      componentRef: compRef,
      initialData: {},
      width: 404,
      height: 528,
      title: 'Challenges',
    });
  }

  getDataByAppDefId(appDefId) {
    return this.editorSDK.tpa.app.getDataByAppDefId(this.token, appDefId);
  }

  getAllPages(): Promise<PageData[]> {
    return this.editorSDK.pages.data.getAll();
  }

  getAllComponents(): Promise<ComponentRef[]> {
    return this.editorSDK.components.getAllComponents(this.token);
  }

  getAllComponentsById(appId): Promise<any[]> {
    return this.editorSDK.document.tpa.app.getAllCompsByApplicationId(
      this.token,
      appId,
    );
  }

  getApplicationPages(): Promise<PageData[]> {
    return this.editorSDK.document.pages.getApplicationPages(this.token);
  }

  getComponentRef(id: string): Promise<ComponentRef> {
    return this.editorSDK.components.getById(this.token, { id });
  }

  getPage(compRef: ComponentRef): Promise<PageRef> {
    return this.editorSDK.components.getPage(this.token, {
      componentRef: compRef,
    });
  }

  getComponentData(componentRef: ComponentRef): Promise<any> {
    return this.editorSDK.components.data.get(this.token, {
      componentRef,
    });
  }

  getAppData<T>(appDefinitionId?: string): Promise<T> {
    return this.editorSDK.document.tpa.app.getDataByAppDefId(
      this.token,
      appDefinitionId || this.token,
    );
  }

  getLocale() {
    return this.editorSDK.environment.getLocale();
  }

  openDashboard(url: string) {
    return this.editorSDK.editor.openDashboardPanel(this.token, {
      url,
      closeOtherPanels: false,
    });
  }

  async highlightComponent(compRef: ComponentRef) {
    return this.editorSDK.editor.selection.locateAndHighlightComponentByCompRef(
      this.token,
      { compRef },
    );
  }

  async removeHighlightComponent() {
    return this.editorSDK.editor.selection.clearHighlights(this.token);
  }

  /**
   * Updates the state for one or more pages.
   * Use this functionality to give different edit experience to different pages by defining different
   * pages actions and settings in the appManifest.
   * https://github.com/wix-private/js-platform-editor-sdk/blob/0eac76ff130a1e884c45ef023cb7e5eac865de18/js/modules/document/pages/pages.ts#L150
   */
  setPageState(state: { [index: string]: PageRef[] }): Promise<void> {
    return this.editorSDK.document.pages.setState(this.token, {
      state,
    });
  }

  async addPage(pageId: string) {
    await this.editorSDK.document.tpa.add.component(this.token, {
      componentType: this.editorSDK.document.tpa.TPAComponentType.Page,
      page: {
        pageId,
        shouldNavigate: false,
      },
    });
  }

  deletePages(challengesPages: TPARef[]) {
    return Promise.all(
      challengesPages.map(async (ref) => {
        await this.deletePage(ref);
      }),
    );
  }

  async deleteComponent(ref) {
    return this.editorSDK.components.remove(this.token, { componentRef: ref });
  }

  async deletePage(ref) {
    return this.editorSDK.document.pages.remove(this.token, ref);
  }

  deleteApp() {
    return this.editorSDK.document.tpa.app.delete(this.token);
  }

  async save() {
    return this.editorSDK.document.save(this.token);
  }

  async delay(time = 3000) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
}

export default EditorWrapper;
