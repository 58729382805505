import {
  AppManagerEvent,
  PageActionsEvent,
  PageSettingsTab,
} from './types/manifest';
import {
  PAYMENT_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
} from './app-config';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export function getChallengesManifest(translation) {
  return {
    pages: {
      pageActions: {
        default: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
          {
            title: translation.t('challenge.page.delete'),
            icon: 'delete_icon',
            event: PageActionsEvent.REMOVE,
            type: 'page_remove',
          },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
        [CHALLENGES_LIST_PAGE_MANIFEST_KEY]: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
          {
            title: translation.t('challenge.page.delete'),
            icon: 'delete_icon',
            event: PageActionsEvent.REMOVE,
            type: 'page_remove',
          },
        ],
      },
      pageSettings: {
        default: [
          // {
          //   title: translation.t('challenge.page.info'),
          //   type: PageSettingsTab.PAGE_INFO,
          // },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          // {
          //   title: translation.t('challenge.page.seo'),
          //   type: PageSettingsTab.SEO,
          // },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
        [CHALLENGES_LIST_PAGE_MANIFEST_KEY]: [
          {
            title: translation.t('challenge.page.info'),
            type: PageSettingsTab.PAGE_INFO,
          },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          {
            title: translation.t('challenge.page.seo'),
            type: PageSettingsTab.SEO,
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: translation.t('editor.pages.challenges-pages-tab-name'),
          helpId: '6c8df9e5-1bc0-4b1c-93a7-6f1ea34cefc7',
        },
      },
      applicationActions: {
        default: [],
      },
    },
    appDescriptor: { ...getAppDescriptor(translation.t) },
  };
}

const getAppDescriptor = (translate) => {
  return {
    mainActions: [
      {
        title: translate('editor.app-manager.go-to-dashboard'),
        actionId: AppManagerEvent.OPEN_DASHBOARD,
        icon: 'appManager_settingsAction',
      },
    ],
    customActions: [
      {
        title: translate('editor.manage-my-apps.quick-actions.cta1'),
        actionId: AppManagerEvent.OPEN_CREATE_CHALLENGE,
        icon: 'appManager_addElementsAction',
        type: 'editorActions',
      },
      {
        title: translate('editor.manage-my-apps.quick-actions.cta2'),
        actionId: AppManagerEvent.OPEN_DASHBOARD,
        icon: 'appManager_pagesAction',
        type: 'editorActions',
      },
      {
        title: translate('editor.manage-my-apps.quick-actions.cta3'),
        actionId: AppManagerEvent.OPEN_SP,
        icon: 'appManager_settingsAction',
        type: 'editorActions',
      },
    ],
    defaultActions: {
      learnMoreKB: 'c4b68831-310b-4475-81af-3b7a092ffd89',
    },
  };
};
